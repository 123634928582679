import React, { useState, useEffect, useContext } from "react";
import {fetchData, GET_LOCATION, SOCI_LOCATION_DASHBOARD } from "../../components/Service";
import ChartComponant from '../../components/ChartComponent'
import _ from 'lodash';
import { Link } from "react-router-dom";
import ReviewByMonth from "./ReviewByMonth";
import FanEngagementDemographic from "./FanEngagementDemographic";
import EngagementSentiment from "./EngagementSentiment";
import EngagementStatus from "./EngagementStatus";
import ReviewByNetwork from "./ReviewByNetwork";
import OpenReview from "./OpenReview";
import ReviewSentiment from "./ReviewSentiment";
import SocailMediaContent from "./SocailMediaContent";
import ContentStatus from "./ContentStatus";
import { Context } from '../../components/Context';
import { now } from "lodash";
import { downloadPdf, loadingData } from "../../components/Helper";
import { useLoadScript } from "@react-google-maps/api";
import MapContainer from "./googleMap";
import Select from 'react-select'



function SocailInsight() {
    const [context] = useContext(Context)
    let dashboardTitle = context && context.auth && context.auth.user_group_id === 1 ? 'Admin Dashboard' : 'User Dashboard'
    window.document.title = dashboardTitle;

    const [sociLocation, setSociLocation] = useState([]);
    const [projectId, setProjectIs] = useState(null);
    const [soci, setSoci] = useState(0);
    const [fanEngagedData, setFanEngagedData] = useState(0);
    const [engagementSentiment, setEngagementSentiment] = useState(0);
    const [engagementStatus, setEngagementStatus] = useState(0);
    const [reviewByNetwork, setReviewByNetwork] = useState(0);
    const [openReview, setOpenReview] = useState(0);
    const [reviewByMonthNegative, setReviewByMonthNegative] = useState(0);
    const [reviewByMonthPositive, setReviewByPositive] = useState(0);
    const [reviewByMonthNeutral, setReviewByMonthNeutral] = useState(0);
    const [reviewByMonthAverage, setReviewByMonthAverage] = useState(0);
    const [reviewBySoucre, setReviewBySoucre] = useState(0);
    const [reviewBySentiment, setReviewBySentiment] = useState(0);
    const [refresh, setRefresh] = useState(now())
    // const [location, setLocation] = useState();
    // const [formfield, setFormfield] = useState({
    //     type: 'thirtDay',
    //     startDate: '',
    //     endDate: '',
    // })


    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyCnsDaRuiQrNBJn9txd1VHFurxqdW-t1lk&callback=initMap&libraries=places" // Add your API key
    });


    // useEffect(() => {
    //     fetchData(GET_LOCATION_UNIQUE_URL, 'GET', '', true, false, (res) => {
    //         if (res.records) {
    //             setLocation(res.records);
    //         }
    //     });
    // }, []);

    const reviews_by_source_lbl = reviewBySoucre ? _.drop(Object.keys(reviewBySoucre), 1) : 0;
    const reviews_by_source = {
        labels: reviews_by_source_lbl,
        datasets: [{
            label: 'Open Review',
            data: reviewBySoucre ? _.drop(Object.values(reviewBySoucre), 1) : 0,
            backgroundColor: ['#4662a0', '#aadb87', '#da495b', '#f9b7a5'],
        }]
    };

    const reviews_by_sentiment_lbl = reviewBySentiment ? Object.keys(reviewBySentiment) : 0;
    const reviews_by_sentiment = {
        labels: reviews_by_sentiment_lbl,
        datasets: [{
            label: 'Open Review',
            data: reviewBySentiment ? Object.values(reviewBySentiment) : 0,
            backgroundColor: [
                '#7cb5ec', '#aadb87', '#da495b'
            ],
        }]
    };

    const scales = {
        x: {
            grid: {
                color: 'transparent',
                borderColor: 'transparent'
            },
            ticks: {
                display: false,
            }
        },
        y: {
            beginAtZero: true,
            grid: {
                color: 'transparent',
                borderColor: 'transparent'
            },
            ticks: {
                display: false,
                crossAlign: 'far',
            }
        }
    }
    const plugin_review_by_sentiment = {
        title: {
            display: true,
            text: 'Review By Sentiment',
        }
    }
    const plugin_reviews_by_source = {
        title: {
            display: true,
            text: 'Review By Source',
        }
    }



    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        let project_id = projectId?.value;
        if(project_id){
        document.querySelectorAll(".shadow-sm").forEach(element => element.classList.add('card-loader'));
        document.querySelectorAll(".disabled-button").forEach(element => element.classList.add('disabled'));
        fetchData(SOCI_LOCATION_DASHBOARD+`?projectId=${project_id}`, 'GET', '', true, false, (res) => {
            document.querySelectorAll(".shadow-sm").forEach(element => element.classList.remove('card-loader'));
            document.querySelectorAll(".disabled-button").forEach(element => element.classList.remove('disabled'));
            if (res.records) {

                setSoci(res.records)
                setFanEngagedData(res.records && res.records.engagement_demographic && res.records.engagement_demographic.data)
                setEngagementSentiment(res.records.engagement_sentiment && res.records.engagement_sentiment);
                setEngagementStatus((res.records.engagement_status && res.records.engagement_status.data) && res.records.engagement_status.data);
                setReviewByNetwork((res.records.reviews_by_network && res.records.reviews_by_network.data) && res.records.reviews_by_network.data);
                setOpenReview((res.records.open_reviews && res.records.open_reviews.data) && res.records.open_reviews.data.open.buckets);
                setReviewByMonthNegative(res.records.reviews_by_month && res.records.reviews_by_month[0]);
                setReviewByPositive(res.records.reviews_by_month && res.records.reviews_by_month[1]);
                setReviewByMonthNeutral(res.records.reviews_by_month && res.records.reviews_by_month[2]);
                setReviewByMonthAverage(res.records.reviews_by_month && res.records.reviews_by_month[3]);
                setReviewBySoucre(res.records.reviews_by_source && res.records.reviews_by_source);
                setReviewBySentiment(res.records.reviews_by_sentiment && res.records.reviews_by_sentiment);
            }
        }, false, signal, false);
        }
        return () => {
            controller.abort()
        }
    }, [refresh,projectId])

    useEffect(() => {
        // fetchData(GET_LOCATION + '?' + 'soci', 'GET', '', true, false, (res) => {
        fetchData(`${GET_LOCATION}?soci&groupId=64332`, 'GET', '', true, false, (res) => {
            if (res.records) {
                setSociLocation(res.records)
                if(res.records.length > 0){
                    setProjectIs(res.records[0])
                }
            }else{
                document.querySelectorAll(".shadow-sm").forEach(element => element.classList.remove('card-loader'));
                document.querySelectorAll(".disabled-button").forEach(element => element.classList.remove('disabled'));
            }
        }, false, false, false);

    }, [])

    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-2 text-center text-md-start ms-xl-0 ms-md-4">
                            <h4 className="page-heading">{dashboardTitle}</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-10 dashboard-link">
                            {context && context.auth && context.auth.user_group_id === 1 && context.auth.super_admin === "yes" &&
                                <>
                                    <Link to="../admin-dashboard" className="admin-notification me-2 me-md-3 d-none d-sm-inline-flex">Overview</Link>
                                    <Link to="../dashboard-listing" className="admin-notification me-2 me-md-3 d-none d-sm-inline-flex"> Listings </Link>
                                    <Link to="../soci-dashboard" className="admin-notification me-2 me-md-3 d-none d-sm-inline-flex main-link active">Social insights</Link>
                                    <Link to="../reputation-dashboard" className="admin-notification me-2 me-md-3" >Reputation</Link>
                                </>
                            }
                            {context && context.auth && (context.auth.user_group_id === 3 || (context.auth.user_group_id === 1 && context.auth.super_admin === "no")) && context.auth.permission ? context.auth.permission.map((ele, num) => {
                                return (
                                    <>
                                        {ele.permission === 'Common Dashboard' && ele.view === 'yes' ? <Link to="../admin-dashboard" className="admin-notification me-2 me-md-3 d-none d-sm-inline-flex">Overview</Link> : ''}
                                        {ele.permission === 'Listing Dashboard' && ele.view === 'yes' ? <Link to="../dashboard-listing" className="admin-notification me-2 me-md-3 d-none d-sm-inline-flex"> Listings </Link> : ''}
                                        {ele.permission === 'Social Media Dashboard' && ele.view === 'yes' ? <Link to="../soci-dashboard" className="admin-notification me-2 me-md-3 d-none d-sm-inline-flex main-link active">Social insights</Link> : ''}
                                        {ele.permission === 'Reputation Management Reviews' && ele.view === 'yes' ? <Link to="../reputation-dashboard" className="admin-notification me-2 me-md-3 d-none d-sm-inline-flex">Reputation</Link> : ''}
                                    </>
                                )
                            }) : ''
                            }
                        </div>
                        <div className="col">
                            <div className="text-end">
                                <button className="btn btn-primary disabled-button me-1" onClick={() => setRefresh(now)} ><svg className="icon" role="img"><use href="#icon_refresh" /></svg></button>
                                <button className="btn btn-primary disabled-button" onClick={() => { loadingData(true); downloadPdf('generatePDF', 'Social_Insight_') }}><svg className="icon" role="img"><use href="#icon_pdf" /></svg></button>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-4 mt-3">
                            <Select
                                placeholder={'-- Select Location --'}
                                value={projectId}
                                options={sociLocation}
                                onChange={(e) => setProjectIs(e)}
                                isSearchable
                                name="project_id[]"
                                id="soci_locationvvv"
                                className="basic-multi-select1 react-select required"
                                // required={platform === 'Soci' || platform === 'Both' ? true : false}
                            />
                        </div>
                    </div>

                    <div id="generatePDF">
                        <SocailMediaContent data={soci && soci.tiles_summary && soci.tiles_summary.data} />
                        <div className="row g-4 mt-2">
                            <ContentStatus data={soci} />
                            <FanEngagementDemographic data={fanEngagedData ? fanEngagedData : 0} />
                        </div>
                        <div className="row g-4 mt-2">
                            <EngagementSentiment data={engagementSentiment} />
                            <EngagementStatus data={engagementStatus} />
                            {isLoaded ? <MapContainer data={soci && soci.engagement_ativity} /> : null}
                        </div>

                        <div className="row g-4 mt-2">
                            <ReviewSentiment data={reviewByNetwork} />
                            <ReviewByNetwork data={reviewByNetwork} />
                            <OpenReview data={openReview} />
                        </div>

                        <div className="row g-4 mt-2">
                            <div className="col-12">
                                <div className="card border-light shadow-sm border-0 h-100 card-loader">
                                    <div className="card-header fw-bold">Review By Month</div>
                                    <div className="card-body">
                                        <ReviewByMonth data_negative={reviewByMonthNegative} data_positive={reviewByMonthPositive} data_neutral={reviewByMonthNeutral} data_average={reviewByMonthAverage} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row g-4 mt-2">
                            <div className="col">
                                <div className="card border-light shadow-sm border-0 h-100 card-loader">
                                    <div className="card-header fw-bold">Reviews By Sentiment</div>
                                    <div className="card-body">
                                        <div className="maxw-320 mx-auto">
                                            <ChartComponant key={3} id="chart_bar_review_sentiment" plugins={plugin_review_by_sentiment} label={reviews_by_sentiment_lbl} scale={scales} data={reviews_by_sentiment} type="pie" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card border-light shadow-sm border-0 h-100 card-loader">
                                    <div className="card-header fw-bold">Reviews By Source</div>
                                    <div className="card-body">
                                        <div className="maxw-320 mx-auto">
                                            <ChartComponant key={4} id="chart_bar_review_resource" plugins={plugin_reviews_by_source} label={reviews_by_source_lbl} scale={scales} data={reviews_by_source} type="pie" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}

export default SocailInsight